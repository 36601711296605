.impact-process-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
    background-color: #E0E0E0;
}


.impact-process__description {
    align-self: center;
    font-size: 1.15em;
    padding: 0 8vw;
}


.impact-process__description h1 {
    font-family: 'Rokkitt', serif;
    color: #4D4D4D;
}


.impact-process__description p {
    font-family: 'Scope One', serif;
    color: #4D4D4D;
}


.impact-process__list {
    font-family: 'Scope One', serif;
    color: #4D4D4D;
}

.impact-process__list li {
    margin-bottom: 1.25vh;
}


.impact-process__img {
    align-self: center;
}


.coach-img {
    height: 360px;
    width: 360px;
}

@media (min-width: 1280px) {

.impact-process-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
    background-color: #E0E0E0;
}


.impact-process__description {
    align-self: center;
    font-size: 1.25em;
    padding: 0 8vw;
}


.impact-process__description h1 {
    font-family: 'Rokkitt', serif;
    color: #4D4D4D;
}


.impact-process__description p {
    font-family: 'Scope One', serif;
    color: #4D4D4D;
}


.impact-process__list {
    font-family: 'Scope One', serif;
    color: #4D4D4D;
}

.impact-process__list li {
    margin-bottom: 1.25vh;
}


.impact-process__img {
    align-self: center;
}


.coach-img {
    height: 650px;
    width: 650px;
}

}