.nav-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 9.5vh;
    width: 100%;
    background-color: #FF4747;
    border-bottom: 1.25px double #4D4D4D;
}


.nav-menu__options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 66%;
    align-content: flex-start;
}

.menu-option {
    margin: .35vh 1.15vw;
    font-family: 'Rokkitt', serif;
    font-size: .85em;
    font-weight: 900;
    color: #4D4D4D;
}


.anchor {
    color: #4D4D4D;
    text-decoration: none;
}


.nav-menu__label {
    margin-block-start: 0;
    margin-block-end: 0;
    margin: .25vh 0 0;
    color: #4D4D4D;
    font-family: 'Rokkitt', serif;
    font-weight: 700;
    font-size: .95em;
    text-align: center;
}


.nav-menu__icons {
    align-self: center;
    margin-right: .65vw;
}


.nav-menu__icons a {
    margin: 0 .65vw;
}


.nav-icons {
    height: 18px;
    width: 18px;
}


.icon-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


@media (min-width: 1280px) {

    .nav-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 9.5vh;
        width: 100%;
        background-color: #FF4747;
        border-bottom: 1.25px double #4D4D4D;
    }
    
    
    .nav-menu__options {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .menu-option {
        margin: 0 1.25vw;
        font-family: 'Rokkitt', serif;
        font-size: 1.35em;
        font-weight: 900;
        color: #4D4D4D;
    }
    
    
    .anchor {
        color: #4D4D4D;
        text-decoration: none;
    }
    
    
    .nav-menu__label {
        margin-block-start: 0;
        margin-block-end: 0;
        margin: .85vh 0 0;
        color: #4D4D4D;
        font-family: 'Rokkitt', serif;
        font-weight: 700;
        font-size: 1.25em;
        text-align: center;
    }
    
    
    .nav-menu__icons {
        align-self: center;
        margin-right: 1.25vw;
    }
    
    
    .nav-menu__icons a {
        margin: 0 .45vw;
    }


    .icon-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

}

