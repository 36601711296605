.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: url('../assets/stock_foto.png');
  background-position: 88% 70%;
  background-repeat: no-repeat;
  background-size: auto;
}

.stock-image {
  height: 70vh;
  width: 75%;
  padding: 0.5%;
  border: 4px #4D4D4D double;
}

.title-and-svg {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0;
}

/* .name-text {
  width: 40vw;
  margin: 0 0;
  padding: 0 0;
  color: #E0E0E0;
  font-family: 'Rokkitt', serif;
  font-size: 1.25em;
  font-weight: 900;
} */


.title-logo {
  height: 275px;
  width: 275px;
  opacity: 1;
}

.trading-card-container {
  display: flex;
  justify-content: center;
}

.double-chevrons-down {
  animation: motiondown .9s ease infinite;
  color: #E0E0E0;
}

.mission-text,
.vision-text {
  margin: 0 0;
  padding: 0 0;
  color: #4D4D4D;
  font-family: 'Scope One', serif;
  font-size: .6em;
  font-weight: normal;
}

.mission-text {
  position: relative;
}

.mission-text span {
  display: inline-block;
  position: absolute;
  left: -255px;
  top: 288px;
  width: 500px;
  transform: rotate(-90deg);
}

.vision-text {
  position: relative;
}

.vision-text span {
  display: inline-block;
  position: absolute;
  width: 500px;
  left: -245px;
  top: 243px;
  transform: rotate(90deg);
}

@keyframes motiondown {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(7%);
  }
  50% {
    transform: translateY(14%);
  }
  75% {
    transform: translateY(7%);
  }
  100% {
    transform: translateY(0%);
  }
}


@media (max-width: 400px) and (max-height: 665px) {
  .mission-text span {
    display: inline-block;
    position: absolute;
    left: -255px;
    top: 208px;
    width: 500px;
    transform: rotate(-90deg);
  }
}



@media (min-width: 1280px) {

  .App {
    display: flex;
    min-height: 100vh;
    background: url('../assets/stock_photo.png');
    background-position: 40% 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .title-and-svg {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0;
  }
  
  /* .name-text {
    width: 24vw;
    margin: 0 0;
    padding: 0 0;
    color: #E0E0E0;
    font-family: 'Rokkitt', serif;
    font-size: 2.5em;
    font-weight: 900;
  } */


  .title-logo {
    height: 400px;
    width: 400px;
    opacity: 1;
  }

  .trading-card-container {
    display: flex;
    justify-content: center;
  }

  .mission-text,
  .vision-text {
    margin: 0 0;
    padding: 0 0;
    color: #4D4D4D;
    font-family: 'Scope One', serif;
    font-size: .7em;
    font-weight: normal;
  }

  .mission-text {
    position: relative;
    float: left;
  }

  .vision-text {
    position: relative;
    float: right;
  }

  .mission-text span {
    display: inline-block;
    position: absolute;
    left: -255px;
    top: 372px;
    width: 500px;
    transform: rotate(-90deg);
  }

  .vision-text span {
    display: inline-block;
    position: absolute;
    width: 500px;
    left: -245px;
    top: 243px;
    transform: rotate(90deg);
  }

  .stock-image {
    height: 70vh;
    width: 75%;
    padding: 0.5%;
    border: 4px #4D4D4D double;
  }
  
}

@media (min-width: 1280px) and (max-height: 690px) {
  
  .mission-text span {
    display: inline-block;
    position: absolute;
    left: -255px;
    top: 237px;
    width: 500px;
    transform: rotate(-90deg);
  }

}

@media (min-width: 1536px) {

  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: url('../assets/stock_photo.png');
    background-position: 40% 75%;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #E0E0E0;
  }

  .title-and-svg {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0;
  }
  
/*   .name-text {
    width: 19vw;
    margin: 0 0;
    padding: 0 0;
    color: #E0E0E0;
    font-family: 'Rokkitt', serif;
    font-size: 2.5em;
    font-weight: 900;
  } */

  .title-logo {
    height: 400px;
    width: 400px;
    opacity: 1;
  }


  .trading-card-container {
    display: flex;
    justify-content: center;
  }

  .mission-text,
  .vision-text {
    margin: 0 0;
    padding: 0 0;
    color: #4D4D4D;
    font-family: 'Scope One', serif;
    font-size: .7em;
    font-weight: normal;
  }

  .mission-text {
    position: relative;
    float: left;
  }

  .vision-text {
    position: relative;
    float: right;
  }

  .mission-text span {
    display: inline-block;
    position: absolute;
    left: -255px;
    top: 260px;
    width: 500px;
    transform: rotate(-90deg);
  }

  .vision-text span {
    display: inline-block;
    position: absolute;
    width: 500px;
    left: -245px;
    top: 243px;
    transform: rotate(90deg);
  }

  .stock-image {
    height: 70vh;
    width: 75%;
    padding: 0.5%;
    border: 4px #4D4D4D double;
  }
  
}



@media (min-width: 1920px) {

  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: url('../assets/stock_photo.png');
    background-position: 40% 75%;
    background-repeat: no-repeat;
    background-size: auto;
  }

  .title-and-svg {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  /* .name-text {
    width: 19vw;
    margin: 0 0;
    padding: 0 0;
    color: #4D4D4D;
    font-family: 'Rokkitt', serif;
    font-size: 2.5em;
    font-weight: 900;
  } */


  .title-logo {
    height: 400px;
    width: 400px;
    opacity: 1;
  }

  .trading-card-container {
    display: flex;
    justify-content: center;
  }

  .mission-text,
  .vision-text {
    position: relative;
    margin: 0 0;
    padding: 0 0;
    color: #4D4D4D;
    font-family: 'Scope One', serif;
    font-size: .75em;
    font-weight: normal;
  }

  .mission-text {
    float: left;
  }

  .vision-text {
    float: right;
  }

  .mission-text span {
    display: inline-block;
    position: absolute;
    width: 550px;
    left: -280px;
    top: 347px;
    transform: rotate(-90deg);
  }

  .vision-text span {
    display: inline-block;
    position: absolute;
    width: 500px;
    left: -245px;
    top: 243px;
    transform: rotate(90deg);
  }

  .stock-image {
    height: 70vh;
    width: 75%;
    padding: 0.5%;
    border: 4px #4D4D4D double;
  }

}