.works-container {
    min-height: 100vh;
    width: 100%;
    background-color: #E0E0E0;
}


.modal-container-active {
    display: block;
    z-index: 5;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(77, 77, 77, 0.65);
}


.modal-container-hidden {
    display: none;
}


.works__testimonies h1,
.works__projects h1 {
    text-align: center;
    font-family: 'Rokkitt', serif;
    font-size: 2.25em;
}


.works__quote {
    background: #f9f9f9;
    border-left: 16px solid #4D4D4D;
    margin: 1.15em 1.75vw;
    padding: 0.5em 1.25vw;
}


.works__quote::before {
    color: #4D4D4D;
    content: open-quote;
    font-size: 2.25em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}


.works__quote p {
    display: inline;
    font-family: 'Scope One', serif;
    color: #4D4D4D;
    font-size: 0.6em;
}


.works__projects {
    margin: 5vh 0 7.5vh;
}


.projects__images {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.projects__img {
    height: 250px;
    width: 250px;
}


.projects__img-modal {
    z-index: 6;
    height: 360px;
    width: 360px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -180px;
    margin-left: -180px;
}


figcaption {
    font-family: 'Scope One', serif;
    text-align: center;
    font-size: 0.75em;
    background-color: #E0E0E0;
    padding: .35vh 0;
    margin-top: -5px;
}


@media (min-width: 1280px) {

.works-container {
    height: 130vh;
    width: 100%;
    background-color: #E0E0E0;
}


.modal-container-active {
    display: block;
    z-index: 5;
    position: absolute;
    height: 130vh;
    width: 100vw;
    background-color: rgba(77, 77, 77, 0.65);
}


.modal-container-hidden {
    display: none;
}


.works__testimonies h1,
.works__projects h1 {
    text-align: center;
    font-family: 'Rokkitt', serif;
    font-size: 2.75em;
}


.works__quote {
    background: #f9f9f9;
    border-left: 16px solid #4D4D4D;
    margin: 1.5em 27.5vw;
    padding: 0.5em 1.25vw;
}


.works__quote::before {
    color: #4D4D4D;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}


.works__quote p {
    display: inline;
    font-family: 'Scope One', serif;
    color: #4D4D4D;
    font-size: 0.75em;
}


.works__projects {
    margin: 5vh 0 7.5vh;
}


.projects__images {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.projects__img {
    height: 250px;
    width: 250px;
}


.projects__img-modal {
    z-index: 6;
    height: 360px;
    width: 360px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -180px;
    margin-left: -180px;
}


figcaption {
    font-family: 'Scope One', serif;
    text-align: center;
    font-size: 0.75em;
    background-color: #E0E0E0;
    padding: .35vh 0;
    margin-top: -5px;
}

}