.about-us-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    min-height: 150vh;
    background-color: #E0E0E0;
}

.about-us {
    min-height: 45vh;
    width: 85vw;
    margin: 10vh 0 0;
    border: 4px #4D4D4D double;
    background-color: #CCCCCC;
}

.about-us__heading {
    margin: 2.5vh 0 2vh;
    color: #4D4D4D;
    font-family: 'Rokkitt', serif;
    font-size: 1.75em;
    font-weight: 900;
    text-align: center;
}

.about-us__text {
    margin: 0 0;
    padding: 0 3vw;
    color: #4D4D4D;
    font-family: 'Scope One', serif;
    font-size: 0.95em;
    font-weight: normal;
}


.staff-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 250vh;
    width: 100%;
    margin: 7.5vh 0 7.5vh;
}


.staff-card {
    display: flex;
    flex-direction: column;
    margin: 0 6.5vw;
    border: 4px #4D4D4D double;
    background-color: #CCCCCC;
}


.staff-card-picframe {
    align-self: center;
}


.staff-card__img {
    height: 200px;
    width: 200px;
}


.staff-card__piclabel {
    display: flex;
    flex-direction: column;
}


.staff-icons {
    margin: 0.5vh 0 0;
}


.staff-icons a {
    margin: 0 1vw;
}


.staff-card__text {
    align-self: center;
    padding: 0 5vw;
    font-family: 'Scope One', serif;
    font-size: 0.75em;
}



@media (min-width: 1280px) {

    .about-us-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 120vh;
        background-color: #E0E0E0;
    }

    .about-us {
        min-height: 40vh;
        width: 66vw;
        margin: 10vh 10vw 0;
        border: 4px #4D4D4D double;
        background-color: #CCCCCC;
    }

    .about-us__heading {
        margin: 2.5vh 0 2vh;
        color: #4D4D4D;
        font-family: 'Rokkitt', serif;
        font-size: 2.35em;
        font-weight: 900;
        text-align: center;
    }
    
    .about-us__text {
        margin: 0 0;
        padding: 0 3.5vw;
        color: #4D4D4D;
        font-family: 'Scope One', serif;
        font-size: 1.35em;
        font-weight: normal;
    }

    .staff-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 175vh;
        margin: 10vh 0 10vh;
    }


    .staff-card {
        display: flex;
        flex-direction: row;
        margin: 0 18vw;
        border: 4px #4D4D4D double;
        background-color: #CCCCCC;
    }


    .staff-card-picframe {
        align-self: center;
    }


    .staff-card__img {
        height: 275px;
        width: 275px;
    }


    .staff-card__text {
        align-self: center;
        padding: 0 2vw;
        font-family: 'Scope One', serif;
        font-size: 1.05em;
    }
    
  }

@media (min-width: 1536px) {

    .about-us-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 120vh;
        background-color: #E0E0E0;
    }

    .about-us {
        min-height: 40vh;
        width: 66vw;
        margin: 10vh 10vw 0;
        border: 4px #4D4D4D double;
        background-color: #CCCCCC;
    }

    .about-us__heading {
        margin: 2.5vh 0 2vh;
        color: #4D4D4D;
        font-family: 'Rokkitt', serif;
        font-size: 2.35em;
        font-weight: 900;
        text-align: center;
    }
    
    .about-us__text {
        margin: 0 0;
        padding: 0 3.5vw;
        color: #4D4D4D;
        font-family: 'Scope One', serif;
        font-size: 1.35em;
        font-weight: normal;
    }

    .staff-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 160vh;
        margin: 10vh 0 10vh;
    }


    .staff-card {
        display: flex;
        flex-direction: row;
        margin: 0 20vw;
        border: 4px #4D4D4D double;
        background-color: #CCCCCC;
    }


    .staff-card-picframe {
        align-self: center;
    }


    .staff-card__img {
        height: 225px;
        width: 225px;
    }


    .staff-card__text {
        align-self: center;
        padding: 0 2vw;
        font-family: 'Scope One', serif;
        font-size: 1.05em;
    }
    
  }

@media (min-width: 1920px) {

    .about-us-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 110vh;
        background-color: #E0E0E0;
    }

    .about-us {
        min-height: 40vh;
        width: 66vw;
        margin: 10vh 10vw 0;
        border: 4px #4D4D4D double;
        background-color: #CCCCCC;
    }

    .about-us__heading {
        margin: 2.5vh 0 2vh;
        color: #4D4D4D;
        font-family: 'Rokkitt', serif;
        font-size: 2.75em;
        font-weight: 900;
        text-align: center;
    }
    
    .about-us__text {
        margin: 0 0;
        padding: 0 3vw;
        color: #4D4D4D;
        font-family: 'Scope One', serif;
        font-size: 1.55em;
        font-weight: normal;
    }


    .staff-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 135vh;
        margin: 10vh 0 10vh;
    }


    .staff-card {
        display: flex;
        flex-direction: row;
        margin: 0 10vw;
        border: 4px #4D4D4D double;
        background-color: #CCCCCC;
    }


    .staff-card-picframe {
        align-self: center;
    }


    .staff-card__img {
        height: 275px;
        width: 275px;
    }


    .staff-card__text {
        align-self: center;
        padding: 0 5vw;
        font-family: 'Scope One', serif;
        font-size: 1.25em;
    }

}