.services-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color: #E0E0E0;
}


.services__img {
    align-self: center;
}


.ball-img {
    width: 360px;
    height: 360px;
}


.services__description {
    align-self: center;
    font-size: 1.15em;
    padding: 0 8vw;
}


.services__description h1 {
    font-family: 'Rokkitt', serif;
    color: #4D4D4D;
}


.services__description p {
    font-family: 'Scope One', serif;
    color: #4D4D4D;
}


.services__list {
    font-family: 'Scope One', serif;
    color: #4D4D4D;
}


@media (min-width: 1280px) {

.services-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    width: 100%;
    background-color: #E0E0E0;
}


.services__img {
    align-self: center;
}


.ball-img {
    width: 650px;
    height: 650px;
}


.services__description {
    align-self: center;
    font-size: 1.25em;
    padding: 0 8vw;
}


.services__description h1 {
    font-family: 'Rokkitt', serif;
    color: #4D4D4D;
}


.services__description p {
    font-family: 'Scope One', serif;
    color: #4D4D4D;
}


.services__list {
    font-family: 'Scope One', serif;
    color: #4D4D4D;
}

}